import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import {Container, Col, Row} from 'react-bootstrap';

class TagRoute extends React.Component {

componentDidMount() {
    document.body.className = "green";
}

componentWillUnmount() {
  document.body.className = "";
}

  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map((post) => (

      
      <Col 
      key={post.node.fields.slug} xs={12} md={6} lg={4}
      className=''
      >
        <div className='work-container'>
        <Link to={post.node.fields.slug}>
          <div
              className={`tile is-child box notification ${
              post.node.frontmatter.featuredpost ? 'is-featured' : ''
            }`}
          >
          <div>
            {post.node.frontmatter.featuredimage ? (
              <div className="featured-thumbnail position-relative">
                {/* Title Box */}
                    <div className='work-over-container mask'>
                      <div className='h-100 w-100 d-flex justify-content-center align-items-center align-items-center position-relative'>
                        <div className='text-center'>
                          <div  className='work-item-title'>
                            <h1 className='max-w-80'>{post.node.frontmatter.title}</h1>
                          </div>
                        </div> 
                      </div>
                    </div>
                {/* Image */}
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.node.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.node.frontmatter.title}`,
                  }}
                  className='featured'
                />
              </div>
            ) : null}
              {/* Details */}
              <div className='work-item-details w-100 zindex-2 clearfix'>
                <h6 className='float-left'>{post.node.frontmatter.year}</h6>
                <h6 className='float-right'>{post.node.frontmatter.commissioner}</h6>
              </div> 
          </div>
        </div>
      </Link>
      </div>
    </Col>
    ))

    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout className=''>
        <section className="sec-mrg">
          <Helmet title={`${tag} | ${title}`} />
            <Container fluid className=''>
              <Row className="text-center">
                <Col xs={{span: 10, offset: 1}}>
                  <h4 className="text-capitalize mb-5">
                    {tag}s
                  </h4>
                </Col>
            </Row>
            <Container fluid >
              <Row className="">
                  {postLinks}
              </Row>
            </Container>
                  {/* <Link to="/tags/">Browse all tags</Link> */}
          </Container>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            year
            commissioner
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100, traceSVG: {
                  color: "SpringGreen",
                  optTolerance: 0.4,
                  turdSize: 100,
                  turnPolicy: TURNPOLICY_MINORITY
                  blackOnWhite: false
                }) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }        
            }
          }
        }
      }
    }
  }
`
